import { 
  Box,
  Button,
  useTheme,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  Container,
  Typography,
  CircularProgress,
  circularProgressClasses
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";
import { createApiAction } from "../../redux/apiServices";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFilter } from "../../redux/apislice";
import EditIcon from '@mui/icons-material/Edit';

const Category = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);  
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen]=useState(false);
  const {data} = useSelector((state) => state.api);
  const searchtag=useSelector((state)=>state.api.search)
  const [newCategory, setNewCategory] = useState({
    categoryName: ""
  });
  const [loading, setLoading] = useState(false);
  const [validateError, setValidateError] = useState({ categoryName: '' });
  const [errors, setErrors] = useState({ categoryName: '' });
  const [editOpen, setEditOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({
    id: '',
    categoryName: ''
  });

  const getCategories = useCallback(async () => {
    setLoading(true);
    await dispatch(createApiAction('/api/reviews/get_category_list', 'GET'));
    setLoading(false);
  },[dispatch]);

  useEffect(()=>{
      dispatch(clearFilter())
      getCategories();
  },[getCategories,dispatch])


  const addCategory = async () => {
    try {
      setLoading(true)
      await dispatch(createApiAction('/api/admin/add_category', 'POST', { name: newCategory.categoryName })); 
        await getCategories(); // Re-fetch categories
        setOpen(false);
        setNewCategory({ categoryName: '' });
    } catch (err) {
      if(err.message==400)
      {setErrors({ categoryName: "Category already exixt"})}
    }finally{
      setLoading(false)
    }
  };

  const deleteCategory = async (id) => {
    try {
      setLoading(true)
      await dispatch(createApiAction('/api/admin/delete_category', 'POST', { category_id: id }));
      await getCategories(); // Re-fetch categories
      setDeleteOpen(false);
      setSelectedCategory(null);
    } catch (err) {}finally{setLoading(false)}
  };

  const validateCategory = () => {
    let valid = true;
    let newError = { categoryName: "" };
    if (!newCategory.categoryName) {
      newError.categoryName = "Category name is required";
      valid = false;
    }
    setValidateError(newError);
    return valid;
  };
  const validateCategoryEdit = (category) => {
    let valid = true;
    let newError = { categoryName: "" };
    if (!category.categoryName) {
      newError.categoryName = "Category name is required";
      valid = false;
    }
    setValidateError(newError);
    return valid;
  };

  const handleAddCategory = async () => {
    if (validateCategory()) {
      await addCategory();
    }
  };

  const handleDelete = async () => {
    if (selectedCategory) {
      await deleteCategory(selectedCategory.id);
    }
  };

  const handleEdit = (category) => {
  setEditCategory({ id: category.id, categoryName: category.name });
  setEditOpen(true);
};
const updateCategory = async () => {
  if (!validateCategoryEdit(editCategory)) return;

  try {
    setLoading(true)
    await dispatch(createApiAction('/api/admin/edit_category', 'POST', {
      category_id: editCategory.id,
      name: editCategory.categoryName
    }));
    await getCategories();
    setEditOpen(false);
    setEditCategory({ id: '', categoryName: '' });
  } catch (err) {
    setErrors({ categoryName: err.message });
  }finally{
    setLoading(false)
  }
};


  const filteredCategories = Array.isArray(data?.data)
  ? data.data.filter(category =>
      category?.name?.toLowerCase().includes(searchtag.toLowerCase())
    )
  : [];
  const columns = [ 
    { field: "id", headerName: "ID", minWidth: 100,},  
    { field: "name", headerName: "Category", flex: 1,minWidth: 100, },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box
          display="flex"
          gap={2}
          alignItems="center"
          height="100%"
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<EditIcon />}
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<DeleteIcon />}
            onClick={() => {
              setSelectedCategory(params.row);
              setDeleteOpen(true);
            }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Container>
      <Box sx={{
      margin:'16px',
      padding:'4px',
      justifyContent:'center',
    }}>
      <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
            px: 2, // Padding for responsiveness
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              alignItems: 'center',
            },
          }}
        >
          <Box
            sx={{
              marginLeft:'80px',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              '@media (max-width: 600px)': {
                justifyContent: 'center',
                mb: 1, // Margin bottom for spacing in smaller screens
                ml:0
              },
            }}
          >
            <Header
                title="Managing Categories"
              />
          </Box>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={() => setOpen(true)}
            sx={{
              '@media (max-width: 600px)': {
                width: '100%',
              },
            }}
          >
            Add Category
          </Button>
        </Box>
      <Box
        height="75vh"
        width="100%"
        sx={{
          
          "& .MuiDataGrid-root": {
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[900],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.primary[500]} !important`,
          },
        }}
      >
        {loading?(<Container sx={{display:"flex",justifyContent:"center",mt:4}}>
          <CircularProgress />
        </Container>
):(<DataGrid rows={filteredCategories || []} columns={columns} />
      )}
        </Box>
      {/* Dialog for adding category */}
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: `${colors.primary[200]}`,
            borderRadius: 4,
            padding: 2,
          },
          '& .MuiDialogTitle-root': {
            fontSize: 20,
            color: `${colors.primary[700]}`,
            fontWeight: 'bold',
          },
          '& .MuiInput-root::after': {
            borderBottom: `px solid ${colors.primary[700]}`,
          },
          '& .MuiInput-root::before': {
            borderBottom: `1px solid #262429`,
          },
          '& .MuiInput-root:hover::before': {
            borderBottom: `1px solid ${colors.primary[500]} `,
          },
          '& .MuiInput-root:hover::after': {
            borderBottom: `1px solid ${colors.primary[500]}`,
          },
          '& .MuiDialogContentText-root': {
            color: `${colors.primary[700]}`,
          },
          '& .MuiInputLabel-root': {
            color: `${colors.primary[700]}`,
          },
          '& .Mui-focused': {
            "& .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor:
                          colors
                            .greenAccent[200],
                        borderWidth:
                          "2px",
                      },
            fontWeight:"bold",
            color: `${colors.primary[700]}`,
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: `${colors.primary[700]}`,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.primary[700]}`,  // Default outline border color
          },
          '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
          border:`2px solid ${colors.primary[700]}` ,  // Change outline border color on hover
          },
          '& .MuiTextField-root:hover .MuiOutlinedInput-notchedOutline':{
            borderBottom:`2px solid`,
            borderColor: `${colors.primary[700]}`,  // Change outline border color
          },
          '& .MuiInput-root::after:hover' :{
            borderBottom: `2  px solid ${colors.primary[700]}`,
          }
        }}
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedCategory(null);
          setNewCategory({ categoryName: '' });
          setErrors({categoryName:null})
        }}
      >
        <Box padding={"20px 0px 0px 25px"} color={colors.primary[700]}>
          <Typography variant="h5" fontWeight="bold">Add Category</Typography>
        </Box>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            sx={{
              '& .MuiInputBase-input': {
                color: colors.primary[100],
              },
            }}
            label="Category"
            color="secondary"
            type="text"
            fullWidth
            autoComplete="off"
            variant="outlined"
            value={newCategory.categoryName}
            onChange={(e) => {
              setNewCategory({
                ...newCategory,
                categoryName: e.target.value,
              });
              setErrors({ categoryName: '' });
            }}
            onClick={() => {
              setValidateError(prevState => ({ ...prevState, categoryName: '' }));
              setErrors({ categoryName: '' });
            }}
            error={!!validateError.categoryName || !!errors.categoryName}
            helperText={validateError.categoryName || errors.categoryName}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setNewCategory({ categoryName: '' });
              setErrors({categoryName:null})
            }}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddCategory}
            variant="contained"
            color="secondary"
          >
             {loading ? <CircularProgress size={24} /> : 'Add Category'}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for deleting category */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: colors.primary[200],
          },
        }}
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteOpen(false)}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            onClick={handleDelete}
            variant="contained"
            color="secondary"
          >
            {loading ? <CircularProgress size={24} /> : 'Delete Category'}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for editing the category */}
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: `${colors.primary[200]}`,
            borderRadius: 4,
            padding: 2,
          },
          '& .MuiDialogTitle-root': {
            fontSize: 20,
            color: `${colors.primary[700]}`,
            fontWeight: 'bold',
          },
          '& .MuiInput-root::after': {
            borderBottom: `px solid ${colors.primary[700]}`,
          },
          '& .MuiInput-root::before': {
            borderBottom: `1px solid #262429`,
          },
          '& .MuiInput-root:hover::before': {
            borderBottom: `1px solid ${colors.primary[700]} `,
          },
          '& .MuiInput-root:hover::after': {
            borderBottom: `1px solid ${colors.primary[700]}`,
          },
          '& .MuiDialogContentText-root': {
            color: `${colors.primary[700]}`,
          },
          '& .MuiInputLabel-root': {
            color: `${colors.primary[700]}`,
          },
          '& .Mui-focused': {
            "& .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor:
                          colors
                            .greenAccent[200],
                        borderWidth:
                          "2px",
                      },
            fontWeight:"bold",
            color: `${colors.primary[700]}`,
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: `${colors.primary[700]}`,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.primary[700]}`,  // Default outline border color
          },
          '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
          border:`2px solid ${colors.primary[700]}` ,  // Change outline border color on hover
          },
          '& .MuiTextField-root:hover .MuiOutlinedInput-notchedOutline':{
            borderBottom:`2px solid`,
            borderColor: `${colors.primary[700]}`,  // Change outline border color
          },
          '& .MuiInput-root::after:hover' :{
            borderBottom: `2  px solid ${colors.primary[700]}`,
          }
        }}
        open={editOpen}
        onClose={() => {
          setEditOpen(false);
          setEditCategory({ id: '', categoryName: '' });
          setErrors({ categoryName: null });
        }}
      >
      <Box padding={"20px 0px 0px 25px"} color={colors.primary[700]}>
                <Typography variant="h5" fontWeight="bold">Edit Category</Typography>
              </Box>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            sx={{
              '& .MuiInputBase-input': {
                color: colors.primary[700],
              },
            }}
            label="Category"
            color="secondary"
            type="text"
            fullWidth
            variant="outlined"
            value={editCategory.categoryName}
            onChange={(e) => {
              setEditCategory({
                ...editCategory,
                categoryName: e.target.value,
              });
              setErrors({ categoryName: '' });
            }}
            onClick={() => {
              setValidateError(prevState => ({ ...prevState, categoryName: '' }));
              setErrors({ categoryName: '' });
            }}
            error={!!validateError.categoryName || !!errors.categoryName}
            helperText={validateError.categoryName || errors.categoryName}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditOpen(false);
              setEditCategory({ id: '', categoryName: '' });
              setErrors({ categoryName: null });
            }}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={updateCategory}
            variant="contained"
            color="secondary"
          >
            {loading ? <CircularProgress size={24}></CircularProgress> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
    </Container>

  );
};

export default Category;
