import { useState } from "react";
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  useTheme,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  Link,
  useNavigate,
} from "react-router-dom";
import {
  loginFailure,
  loginStart,
  loginSuccess,
} from "../../redux/userslice";
import { useDispatch } from "react-redux";
import { tokens } from "../../theme";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { baseUrl } from "../../Base";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [
    userCredentials,
    setUserCredentials,
  ] = useState({
    email: "",
    password: "",
  });
  
  const [
    errorMessage,
    setErrorMessage,
  ] = useState("");
  const [emailError, setEmailError] =
    useState("");
  const [
    passwordError,
    setPasswordError,
  ] = useState("");

  const validationEmail = () => {
    let isValid = true;
    if (!userCredentials.email) {
      setEmailError(
        "Email is required"
      );
      isValid = false;
    } else if (
      !/\S+@\S+\.\S+/.test(
        userCredentials.email
      )
    ) {
      setEmailError("Email is invalid");
      isValid = false;
    }
    return isValid;
  };
  const validationPassword = () => {
    let isValid = true;
    if (!userCredentials.password) {
      setPasswordError(
        "Password is required"
      );
      isValid = false;
    }
    return isValid;
  };
  const handleUserLogin = async (e) => {
    e.preventDefault();

    // Clear previous error messages
    setEmailError("");
    setPasswordError("");
    setErrorMessage("");
    if (
      !validationEmail() &&
      !validationPassword()
    )
      return;
    dispatch(loginStart());
    try {
      const response = await fetch(
        `${baseUrl}/api/admin/login`,
        {
          method: "POST",
          body: JSON.stringify(
            userCredentials
          ),
          headers: {
            "Content-Type":
              "application/json",
          },
        }
      );

      const data =
        await response.json();
      if (response.status === 200) {
        console.log(data.data.data);
        dispatch(
          loginSuccess(data.data)
        );
        localStorage.setItem(
          "access_token",
          data.data.access_token
        );
        localStorage.setItem(
          "refresh_token",
          data.data.refresh_token
        );
        navigate("/dashboard");
      } else if (
        response.status === 403
      ) {
        setErrorMessage(
          "Invalid username or password"
        );
      } else {
        dispatch(loginFailure());
        setErrorMessage(
          data.message ||
            "An error occurred"
        );
      }
    } catch (err) {
      console.error(
        "Login error:",
        err
      );
      dispatch(loginFailure());
      setErrorMessage(
        "An error occurred. Please try again."
      );
    }
  };

  const theme = useTheme();
  const colors = tokens(
    theme.palette.mode
  );

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(174.66deg, #282842 4.27%, #3B2146 100.62%)`,
      }}
    >
      <Container
        maxWidth="sm"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
          color:'white',
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
            height: "100%",
            padding: "20px",
            borderRadius: "20px",
            // background: `linear-gradient(154.66deg, #282842 4.27%, #3B2146 100.62%)`,
            position: "relative", // Added for corner elements
          }}
        >
          <Box>
            <Box
              sx={{
                position: "relative",
                top: 0,
                right: "4%",
                width: "10px",
                height: "10px",
                backgroundColor:
                  "white",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontFamily:
                  "Sharnay Heavy",
                fontSize: "45.44px",
                fontWeight: 700, // Adjusted to make it bold
                lineHeight: "36.66px",
                letterSpacing: "0.05em",
                textAlign: "left",
                border:
                  "3px solid white", // Added border
                padding: "10px 20px", // Added padding inside border
              }}
            >
              ZIGGLU
            </Typography>
            <Box
              sx={{
                position: "relative",
                bottom: 0,
                left: "100%",
                width: "10px",
                height: "10px",
                backgroundColor:
                  "white",
              }}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              fontFamily: "Roboto",
              fontSize: "30px",
              fontWeight: 500,
              lineHeight: "35.16px",
              letterSpacing: "-0.01em",
              textAlign: "left",
            }}
          >
            It’s Social. Media.
          </Typography>
        </Box>
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            borderRadius: "16px",
            width: "100%",
            backgroundColor: `${colors.primary[200]}`,
            height: "120%",
            justifyContent: "center",
            color:'white !important',
          }}
        >
          <Typography
            variant="h3"
            sx={{ cursor: "default" }}
            gutterBottom
          >
            User Login
          </Typography>
          <form
            onSubmit={handleUserLogin}
          >
            <TextField
              color="primary"
              autoComplete="off"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={
                userCredentials.email
              }
              onChange={(e) => {
                setUserCredentials({
                  ...userCredentials,
                  email: e.target.value,
                });
                validationEmail();
                setErrorMessage(
                  setEmailError("")
                );
              }}
              error={!!emailError}
              helperText={emailError}
            />
             <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              margin="normal"
              value={userCredentials.password}
              onChange={(e) => {
                setUserCredentials({
                  ...userCredentials,
                  password: e.target.value,
                });
                validationPassword();
                setPasswordError("");
              }}
              error={!!passwordError}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Link
              to="/forgot-password"
              style={{
                color:
                  colors.redAccent[400],
              }}
            >
              Forgot Password?
            </Link>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor:
                  colors.primary[700],
                display: "flex",
                margin: "auto",
                width: "100%",
                marginTop: "20px",
              }}
            >
              Login
            </Button>
          </form>
          {errorMessage && (
            <div className="popup">
              <Paper
                elevation={5}
                style={{
                  color: `${colors.redAccent[500]}`,
                  backgroundColor: `${colors.primary[400]}`,
                  padding: "20px",
                  marginTop: "20px",
                }}
              >
                <Typography variant="body1">
                  !{errorMessage}
                </Typography>
              </Paper>
            </div>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
