import {
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  CircularProgress,
  Container
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {
  useCallback,
  useState,
  useEffect,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { createApiAction } from "../../redux/apiServices";

const Feedback = () => {
  const theme = useTheme();
  const colors = tokens(
    theme.palette.mode
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('1320'));;
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.api);
  const [loading, setLoading] = useState(true);
  let [processedRows,setProcessedRows]=useState([])
  
  const getUser = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(createApiAction("/api/admin/feedbackList", "GET"));
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getUser();
  },[getUser]);

    // Mapping the data to the respective fields

    useEffect(() => {
      if (Array.isArray(data?.data)) {
        const rowdata = data.data.map((row, index) => ({
          sequence: index + 1,
          id: row.id,
          profile_image: row.user?.profile_image,
          username: row.user?.username,
          name: row.user?.name,
          experience: row.experience,
          message: row.message,
        }));
        setProcessedRows(rowdata);
      }
    }, [data]);


  // // Search functionality
  // const searchtag=useSelector((state)=>state.api.search);
  // const filteredCategories = Array.isArray(data?.data)
  //   ? data.data.filter(category =>
  //       category?.name?.toLowerCase().includes(searchtag.toLowerCase())
  //     )
  //   : [];



  const columns = [
    {
      field: "sequence",
      headerName: "ID",
      flex: 1,
      minWidth: 40,
      maxWidth: 40,
    },
    { 
      field: "profile_image",
      headerName: "Image",
      flex: 0.5,
      minWidth: 60,
      maxWidth: 60,
      renderCell: (params) => {
        const fallbackImage = "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg";
        const handleError = (event) => {
          event.target.src = fallbackImage;
        };
        return (
          <Box
            display="flex"
            gap={2}
            alignItems="center"
            height="100%"
            justifyContent="left"
          >
            <Box
              component="img"
              src={params.value}
              alt="Profile"
              onError={handleError}
              sx={{
                border: `1px solid ${colors.primary[200]}`,
                width: 40,
                height: 40,
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "username",
      headerName: "User-Id",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "experience",
      headerName: "Experience",
      flex: 1,  
      minWidth: 150,
      renderCell: (params) => {
        let color;
        switch (params.value) {
          case "Compliment":
            color = "green";
            break;
          case "Critique":
            color = "red";
            break;
          case "Feature Request":
            color = "purpule";
            break;
          default:
            color = "grey";
        }
        return (
          <Box style={{color: color, fontWeight: 'bold' }}>
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 2,
      minWidth: 250,
      renderCell: (params) => (
        <Box style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {params.value}
        </Box>
      ),
    },
  ];

  return (
    <Box m="0px 20px 20px 20px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: '100%',
          px: 2,
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            '@media (max-width: 600px)': {
              justifyContent: 'center',
              mb: 1,
            },
          }}
        >
          <Header
            title="Feedback"
          />
        </Box>
      </Box>

      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            maxWidth: "100%",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"

          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },  
          "& .name-column--cell": {
            color: colors.grey[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.redAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            maxHeight: "100%",
          },
          "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[900],
            },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            fontFamily: "Sharnay Heavy",
          },
          '& .MuiDataGrid-scrollbar': {
            width: "7px"
          },
        }}
      >
        {loading ? (
          <Container sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress />
          </Container>
        ) : (
          <DataGrid
            sx={{ overflow: 'auto' }}
            columns={columns}
            rows={processedRows || []}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
          />
        )}
      </Box>
    </Box>
  );
};

export default Feedback;
