import React, {
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import {
  useLocation,
  useParams,
} from "react-router-dom";
import { baseUrl } from "../../Base";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Avatar,
  styled,
  IconButton,
  Box,
  Container,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { toTitleCase } from "../utils/TitleCase";
import StarRating from "../utils/CalculateRating";
import { openGoogleMaps } from "../utils/OpenGooglMaps";
import { Comment } from "../../components/Comments";
import { createApiAction } from "../../redux/apiServices";
import axios from "axios";
import Header from "../../components/Header";

const UserReview = () => {
  const theme = useTheme();
  const colors = tokens(
    theme.palette.mode
  );
  const userDetail = useSelector(
    (state) => state.api.data
  );
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [userReviews, setUserReviews] =
    useState(null);
  const [expanded, setExpanded] =
    useState(false);
  const {
    name,
    profile_image,
    email,
    username,
    dob,
  } = userDetail?.data || {};
  const location = useLocation();
  const url = location.pathname;
  const userId = url.split("/")[2];
  const [loading, setLoading] =
    useState(true);
  const accessToken =
    localStorage.getItem(
      "access_token"
    );
  const [category, setCategory] =
    useState();

  //redirect to page has bugs
  const getCategory =
    useCallback(async () => {
      await axios
        .get(
          `${baseUrl}/api/reviews/get_category_list`,
          {
            headers: {
              "Content-Type":
                "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((res) => {
          setCategory(res.data.data);
        });
    }, []);
  const fetchUserDetails = useCallback(
    async (id) => {
      if (!userDetail.data?.review) {
        await dispatch(
          createApiAction(
            "/api/admin/get_user_detail",
            "POST",
            {
              user_id: id,
            }
          )
        ).then(() => {
          const reviews =
            userDetail.data.reviews?.find(
              (data) =>
                data.id ===
                parseInt(slug)
            );
          setUserReviews(reviews);
          setLoading(false);
        });
      } else {
        const reviews =
          userDetail.data?.reviews?.find(
            (data) =>
              data.id === parseInt(slug)
          );
        setUserReviews(reviews);
        setLoading(false);
      }
    },
    []
  );
  const findCategories = () => {
    const name =
      category?.find(
        (value) =>
          value.id ===
          userReviews.review_category_id
      )?.name || "";
    return toTitleCase(name);
  };
  useEffect(() => {
    getCategory();
    fetchUserDetails(userId);
  }, [fetchUserDetails, getCategory]);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand
      ? "rotate(0deg)"
      : "rotate(180deg)",
    transition:
      theme.transitions.create(
        "transform",
        {
          duration:
            theme.transitions.duration
              .shortest,
        }
      ),
  }));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (loading && !userReviews) {
    return (
      <Box
        m="0px 16px 16px 16px"
        sx={{
          cursor: "default",
          display: "flex",
          flexDirection: "column",
          height: "90vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      m="0px 10px 10px 10px"
      sx={{
        cursor: "default",
        display: "flex",
        flexDirection: "column",
        height: "90vh",
        overflow: "auto",
        padding: "20px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: "100%",
          px: 2,
          "@media (max-width: 600px)": {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <Box
          sx={{
            mb: 1,
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            "@media (max-width: 600px)":
              {
                justifyContent:
                  "center",
                mb: 1,
              },
          }}
        >
          <Header title="Detail Reviews" />
        </Box>
      </Box>
      <Card
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          alignItems: "center",
          padding: "20px",
          backgroundColor:
            colors.primary[200],
          overflow: "auto",
          gap: "8px",
        }}
      >
        {/* Top Card */}
        <Container
          sx={{
            color:'white !important',
            display: "flex",
            maxWidth: "100% !important",
            flex: "1, 1,auto",
            backgroundColor:
              colors.primary[700],
            alignItems: "flex-start",
            padding: "10px",
            gap: "4px",
            width: "100%",
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              src={profile_image}
              alt={name}
              sx={{
                width: 80,
                height: 80,
                margin: "4px",
                "& .MuiAvatar-img": {
                  objectFit: "contain",
                },
              }}
            ></Avatar>
            <Typography
              m="4px"
              variant="h4"
            >
              {username}
            </Typography>
          </Box>
          <Box m="4px">
            <Typography variant="h4">
              {name}
            </Typography>
            <Typography variant="body1">
              {email}
            </Typography>
            <Typography variant="body2">
              {dob
                ? new Date(
                    dob
                  ).toDateString()
                : ""}
            </Typography>
          </Box>
        </Container>

        {/* Card Content */}
        {userReviews && (
          <Card
            sx={{
              display: "flex",
              flex: "1 1 auto",
              padding: "0px",
              overflow: "auto",
              width: "100%",
              backgroundColor:
                colors.primary[600],
              justifyContent:
                "space-between",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flex: "1 1 auto",
              }}
            >
              <Box
                sx={{
                  maxWidth: "30vh",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    padding:
                      "10px 20px 10px 5px",
                    height:
                      "fit-content",
                    maxWidth: "30vh",
                    maxHeight: "45vh",
                    objectFit:
                      "contain",
                  }}
                  image={
                    userReviews.media_url
                  }
                  alt={
                    userReviews.media_title
                  }
                />
                <Typography
                  component="div"
                  variant="h6"
                  sx={{
                    padding:
                      "10px 20px 10px 5px",
                    color:
                      colors
                        .primary[100],
                    fontWeight: "bold",
                    fontSize: 20,
                    maxWidth: "35vh", // Ensure the text does not exceed this width
                    wordBreak:
                      "break-word", // Break long words to fit within the container
                    overflowWrap:
                      "break-word", // Ensure the text wraps within the container
                  }}
                >
                  {toTitleCase(
                    userReviews.media_title
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="div"
                  sx={{
                    paddingLeft: "10px",
                    color:
                      colors
                        .primary[100],
                    fontWeight: "bold",
                    fontSize: 15,
                  }}
                >
                  Rating:{" "}
                  <StarRating
                    rating={
                      userReviews.rating
                    }
                  />
                </Typography>
                {userReviews.latitude &&
                userReviews.longitude ? (
                  <Box
                    sx={{
                      paddingLeft:
                        "10px",
                      display: "flex",
                      flexDirection:
                        "row",
                      gap: 2,
                      alignItems:
                        "center",
                      marginTop:
                        "0.5rem",
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      component="div"
                    >
                      Location:
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="sm"
                      onClick={() =>
                        openGoogleMaps(
                          userReviews.latitude,
                          userReviews.longitude
                        )
                      }
                    >
                      Get Location
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>

              {/* Side details */}
              <Box
                sx={{
                  cursor: "default",
                  display: "flex",
                  flex: "1 1 auto ",
                  flexDirection:
                    "column",
                  overflow: "auto",
                }}
              >
                <Box>
                  <TableContainer>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight:
                                "bold",
                              whiteSpace:
                                "normal",
                              wordWrap:
                                "break-word",
                            }}
                          >
                            Enjoyed
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace:
                                "normal",
                              wordWrap:
                                "break-word",
                            }}
                          >
                            {userReviews.enjoyed
                              ? "Yes"
                              : "No"}
                          </TableCell>
                        </TableRow>
                        {userReviews.enjoyed ? (
                          <>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight:
                                    "bold",
                                  whiteSpace:
                                    "normal",
                                  wordWrap:
                                    "break-word",
                                }}
                              >
                                Two-Word Review
                              </TableCell>
                              <TableCell
                                sx={{
                                  whiteSpace:
                                    "normal",
                                  wordWrap:
                                    "break-word",
                                }}
                              >
                                {
                                  userReviews.review_short
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight:
                                    "bold",
                                  whiteSpace:
                                    "normal",
                                  wordWrap:
                                    "break-word",
                                }}
                              >
                                Full Review
                              </TableCell>
                              <TableCell
                                sx={{
                                  whiteSpace:
                                    "normal",
                                  wordWrap:
                                    "break-word",
                                }}
                              >
                                {
                                  userReviews.review_full
                                }
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <TableRow>
                            <TableCell
                              sx={{
                                fontWeight:
                                  "bold",
                                whiteSpace:
                                  "normal",
                                wordWrap:
                                  "break-word",
                              }}
                            >
                              Note
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace:
                                  "normal",
                                wordWrap:
                                  "break-word",
                              }}
                            >
                              {
                                userReviews.note
                              }
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight:
                                "bold",
                              whiteSpace:
                                "normal",
                              wordWrap:
                                "break-word",
                            }}
                          >
                            Category
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace:
                                "normal",
                              wordWrap:
                                "break-word",
                            }}
                          >
                            {findCategories()}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight:
                                "bold",
                              whiteSpace:
                                "normal",
                              wordWrap:
                                "break-word",
                            }}
                          >
                            Posted On
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace:
                                "normal",
                              wordWrap:
                                "break-word",
                            }}
                          >
                            {new Date(
                              userReviews.createdAt
                            ).toDateString()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {/* comment sections */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems:
                      "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      paddingLeft:
                        "4px",
                      fontWeight:
                        "bold",
                    }}
                  >
                    Comments
                  </Typography>
                  <ExpandMore
                    expand={expanded}
                    onClick={
                      handleExpandClick
                    }
                    aria-expanded={
                      expanded
                    }
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Box>
                {expanded && (
                  <Box
                    sx={{
                      marginTop: "8px",
                      cursor: "default",
                      display: "flex",
                      flex: "1 1 auto",
                      flexDirection:
                        "column",
                      overflow: "auto",
                      backgroundColor: `${colors.primary[300]}`,
                      borderRadius:
                        "8px",
                    }}
                  >
                    {userReviews
                      .review_comments
                      .length > 0 ? (
                      userReviews.review_comments.map(
                        (comment) => (
                          <Comment
                            key={
                              comment.id
                            }
                            comment={
                              comment
                            }
                          />
                        )
                      )
                    ) : (
                      <Typography
                        sx={{
                          color: `${colors.primary[100]}`,
                          fontSize:
                            "1.2rem",
                          margin: "8px",
                        }}
                      >
                        No Comments
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        )}
      </Card>
    </Box>
  );
};

export default UserReview;
