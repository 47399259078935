import { useTheme } from "@emotion/react";
import { Avatar, Button, CardMedia, Collapse, IconButton, Popover, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from '@mui/icons-material/Check';import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { toTitleCase } from "../utils/TitleCase";
import { Box } from "@mui/system";
import { tokens } from "../../theme";
import StarRating from "../utils/CalculateRating";

const fallbackImage = "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg";

const Row = ({ category,row, handleIgnore, handleDeleteButtonClick }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const cellRef = useRef(null);
  const handleArrowClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleScroll = () => {
    if (isMobile && cellRef.current) {
      setAnchorEl(cellRef.current);
    }
  };
  const findCategory=()=>{
    const name =category.find(value=>value.id===row.review.review_category_id)?.name||'';
    return toTitleCase(name);
  }
  useEffect(() => { 
    const table = cellRef.current ? cellRef.current.closest('table') : null;
    if (table) {
      table.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (table) {
        table.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isMobile]);

  const openpop = Boolean(anchorEl);
  const id = openpop ? 'simple-popover' : undefined;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '5%' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" maxWidth="200px">
          <img src={row.review.media_url} alt={row.review.media_title} style={{ maxWidth: '100%', height: '100px', objectFit: 'contain' }} />
        </TableCell>
        <TableCell component="th" scope="row" sx={{ width: '15%', maxHeight: '96px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography  sx={{  fontSize:'1rem',display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>{toTitleCase(row.review.media_title)}</Typography>
        </TableCell>
        {/* posted by user */}
        <TableCell align="center" sx={{ width: '10%', maxHeight: '96px' }}>
        <Box sx={{
            display: 'flex',
            flex:'1 1 50%',
            flexDirection: 'row ',
            justifyContent: 'left',
          }}>
          <Box sx={{ display: 'grid', flexDirection: 'column', alignItems: 'center', }}>
            <Avatar
              src={row.review.user?.profile_image}
              alt={'name'}
              sx={{ width: 40, height: 40, margin: '4px','& .MuiAvatar-img':{
                  objectFit:'contain'
                } }}
            />
              {row.review.user?.username?<Box sx={{display:'flex',
                  justifyContent:'center',
                  width:'100%',
                  color:'white',
                  borderRadius:'12px',
                  border:`1px solid ${colors.primary[700]}`,
                  backgroundColor:`${colors.primary[700]}`}}>
              <Typography  m="4px" variant="h6">{row.review.user?.username}</Typography>
              </Box>:''}
          </Box>
          <Box m="4px">
            <Typography variant="h5">{row.review.user?.name}</Typography>
            <Typography variant="body2">{row.review.user?.email}</Typography>
            </Box>
        </Box>
        </TableCell>
        {/* Reported By */}
        <TableCell align="center" sx={{ width: '15%', maxHeight: '96px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <Box sx={{
            display: 'flex',
            flex:'1 1 50%',
            flexDirection: 'row ',
            justifyContent: 'left',
          }}>
          <Box sx={{ display: 'grid', flexDirection: 'column', alignItems: 'center', }}>
            <Avatar
              src={row.user?.profile_image}
              alt={'name'}
              sx={{ width: 40, height: 40, margin: '4px','& .MuiAvatar-img':{
                  objectFit:'contain'
                } }}
            />
            {row.user.username?<Box sx={{display:'flex',
                justifyContent:'center',
                width:'100%',
                borderRadius:'12px',
                color:'white',
                border:`1px solid ${colors.primary[700]}`,
                backgroundColor:`${colors.primary[700]}`}}>
            <Typography  m="4px" variant="h6">{row.user.username}</Typography>
            </Box>:''}  
          </Box>
          <Box m="4px">
            <Typography variant="h5">{row.user?.name}</Typography>
            <Typography variant="body2">{row.user?.email}</Typography>
            </Box>
        </Box>
        </TableCell>
        
        <TableCell align="center" sx={{ width: '10%', maxHeight: '96px' }} ref={cellRef}>
          <Box display="flex" gap={2} alignItems="center" height="100%" justifyContent="center" sx={{ flex: '1 1 auto' }}>
            {isMobile ? (
              <>
                <IconButton size="small" onClick={handleArrowClick}>
                  {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
                <Popover
                  id={id}
                  open={openpop}
                  anchorEl={anchorEl}
                  onClose={handleArrowClick}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box display="flex" flexDirection="column" alignItems="center" gap={1} p={1} sx={{ bgcolor: '#6b5d8f' }}>
                    <Button variant="contained" color="secondary" size="small" fullWidth startIcon={<CheckIcon />} onClick={() => handleIgnore(row)}>
                      Ignore
                    </Button>
                    <Button variant="contained" color="primary" size="small" fullWidth startIcon={<DeleteIcon />} onClick={() => handleDeleteButtonClick(row)}>
                      Delete
                    </Button>
                  </Box>
                </Popover>
              </>
            ) : (
              <>
                <Button variant="contained" color="secondary" size="small" startIcon={<CheckIcon />} onClick={() => handleIgnore(row)}>
                  Ignore
                </Button>
                <Button variant="contained" color="primary" size="small" startIcon={<DeleteIcon />} onClick={() => handleDeleteButtonClick(row)}>
                  Delete
                </Button>
              </>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        {/* More details */}
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          {/* Posted by user details */}
          <Box 
          sx={{ 
            padding: '8px', 
            margin: 1, 
            backgroundColor: colors.primary[200], 
            borderRadius: '10px',
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
              <Typography variant="h4"  component="div">
                Review Details
              </Typography>
              <Box m="1px" sx={{
                  display:'flex',
                  flex:'1 1 auto',
                  flexDirection:'row',
                  overflow:"auto",
                  maxHeight: '83vh',
                  padding:'2px',
                  borderRadius:'10px'}}>
            <Box sx={{
              padding:'px',
              maxWidth:'50%'
            }}>
            <CardMedia
              component="img"
              sx={{
                padding: "10px 20px 10px 5px",
                height: "fit-content",
                maxWidth: "30vh",
                maxHeight: "45vh",
                objectFit: "contain",
              }}
              image={row.review.media_url}
              alt={row.review.media_title}
            />

            <TableContainer>
                <Table size="small">
                  <TableBody>
                 
                </TableBody>
              </Table>
            </TableContainer>
            </Box>
            <Box sx={{ flex: '1 1 50%', padding: '16px' }}>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                  <TableRow>
                      <TableCell sx={{ fontSize:'1rem',fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Media_title</TableCell>
                      <TableCell sx={{ fontSize:'1rem', whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.review.media_title}</TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell sx={{  fontSize:'1rem',fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Ratings</TableCell>
                      <TableCell sx={{ fontSize:'1rem', whiteSpace: 'normal', wordWrap: 'break-word' }}><StarRating rating={row.review.rating} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{  fontSize:'1rem',fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Enjoyed</TableCell>
                      <TableCell sx={{ fontSize:'1rem', whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.review.enjoyed? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{  fontSize:'1rem',fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Category</TableCell>
                      <TableCell sx={{  fontSize:'1rem',whiteSpace: 'normal', wordWrap: 'break-word' }}>{findCategory()}</TableCell>
                    </TableRow>
                    {row.review.enjoyed?(<>
                      <TableRow>
                      <TableCell sx={{  fontSize:'1rem',fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Review</TableCell>
                      <TableCell sx={{  fontSize:'1rem',whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.review.review_short}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{  fontSize:'1rem',fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Description</TableCell>
                      <TableCell sx={{ fontSize:'1rem', whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.review.review_full}</TableCell>
                    </TableRow></>):
                    (<>
                    <TableRow>
                      <TableCell sx={{  fontSize:'1rem',fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Note</TableCell>
                      <TableCell sx={{  fontSize:'1rem',whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.review.note}</TableCell>
                    </TableRow>
                    </>)}
                  </TableBody>
                  </Table>
                </TableContainer>
            </Box>
              </Box>
              </Box>            
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
