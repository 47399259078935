import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box 
    sx={{
      justifyContent:'center',
      alignItems:'center',
      
    }}
    >
      <Typography
        variant="h2"  
        fontWeight="bold"
        fontSize='45px'
        fontFamily="Sharnay Heavy"
        align="center"
        sx={{
          background: `${colors.grey[100]}`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        {title}
      </Typography>
      <Typography variant="h4" fontFamily="Sharnay Heavy" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;