import React from 'react';
import StarRateIcon from '@mui/icons-material/StarRate';

// Function to calculate the star rating based on the percentage
const calculateStarRating = (rating) => {
  
  const maxRating = 13;

  // Calculate percentage
  const percentage = (rating)/maxRating * 100;

  // Calculate the equivalent number of stars (out of 5)
  const stars = (percentage / 100) * 5;

  return Math.floor(stars);
};

// StarRating component
const StarRating = ({ rating }) => {
  const stars = calculateStarRating(rating);

  return (
    <div style={{ display: 'flex', alignItems: 'center', '@media (max-width: 600px)': {
       // Margin bottom for spacing in smaller screens
    }, }}>
      <span style={{ marginRight: '8px',fontWeight:'bold', fontSize:20 ,'@media (max-width: 600px)': {
        fontSize: 1,
    },}}>{rating}/13</span>
      {Array.from({ length: 5 }, (v, i) => (
        <span key={i} style={{ color: i < stars ? '#FFD700' : '#E0E0E0' }}><StarRateIcon></StarRateIcon></span>
      ))}
      
    </div>
  );
};

export default StarRating;
