import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createApiAction } from "../../redux/apiServices";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Avatar,
  styled,
  Box,
  Button,
  Badge,
  useMediaQuery,
  Popover,
  IconButton,
  Container,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import image from "../utils/images/No_image.jpg";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import StarRating from "../utils/CalculateRating";

const UserDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.api.data);
  const { slug } = useParams();
  const { name, profile_image, email, username, dob, reviews, my_categories, lists } = userDetail.data;
  const fetchUserDetails = useCallback(
    async (id) => {
      await dispatch(
        createApiAction("/api/admin/get_user_detail", "POST", {
          user_id: id,
        })
      );
    },
    [dispatch]
  );

  const BlackTypography = styled(Typography)(({ color }) => ({
    color: color?color:colors.grey[100]
  }));

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const handleDetailInfo = (id) => {
    navigate(`review/${id}`);
    console.log(reviews.find(review => review.id === id));
  };

  useEffect(() => {
    fetchUserDetails(slug);
    console.log(dob)
  }, [slug, fetchUserDetails]);

  //for mobile view
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('840'));
  const ismobileTopCard=useMediaQuery(theme.breakpoints.down('590'))
  const [anchorEl, setAnchorEl] = useState(null);
  const cellRef = useRef(null);

  const handleArrowClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleScroll = () => {
    if (isMobile && cellRef.current) {
      setAnchorEl(cellRef.current);
    }
  };
  const openpop = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (!userDetail) {
    return <BlackTypography>Loading...</BlackTypography>;
  }

  return (
    <Box
      m="0px 10px 10px 10px"
      sx={{
        cursor: 'default',
        display: 'flex',
        flexDirection: 'column',
        height: '90vh',
        overflow: 'auto',
        padding: '20px'
      }}
    >
      <Card
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          alignItems: 'center',
          padding: '20px',
          backgroundColor: colors.primary[800],
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          overflow: 'auto',
        }}
      >
        {/* Top Card */}
        {ismobileTopCard ?(
          <>
          <Box
            sx={{
              color:'white !important',
              display: 'flex',
              flexDirection:'column',
              backgroundColor: colors.primary[800],
              alignItems: 'flex-start',
              padding: '8px',
              gap: '4px',
              width:'100%',
              height:'20%',
              minHeight:'auto',
              maxHeight:'vh', 
              justifyContent:'space-between',
              overflowY:'auto',
              borderRadius:'10px'
            }}
          >
            <Box sx={{
              display: 'flex',
              flex:'1 1 50%',
              flexDirection: 'row ',
              justifyContent: 'left',
            }}>
            <Box sx={{display: 'grid', flexDirection: 'column','& .MuiAvatar-root ':{
              marginLeft:'10px'
            } }}>
              <Avatar
                src={profile_image}
                alt={name}
                sx={{width: 40, height: 40, margin: '4px','& .MuiAvatar-img':{
                    objectFit:'contain'
                  } }}
              />
              <Box sx={{
                display:'flex',
                justifyContent:'center',
                width:'100%',
                borderRadius:'12px',
                border:`1px solid ${colors.primary[700]}`,
                backgroundColor:`${colors.primary[700]}`
                }}>
              <Typography sx={{
                paddingLeft:'3px', paddingRight:'3px',fontWeight: 600,
              }} m="4px" variant="h6">{username}</Typography>
              </Box>
            </Box>
            <Box m="4px">
              <BlackTypography fontWeight="bold" variant="h4">{name}</BlackTypography>
              <BlackTypography variant="body2">{email}</BlackTypography>
              <BlackTypography variant="body3">{(dob!==null)? (new Date(dob).toDateString()):''}</BlackTypography>
            </Box>
            </Box>
            {/* categories */}
            <Box
              container
              direction="row"
              sx={{
                display: 'flex',
                flex:'1 1 50%',
                flexDirection: 'column',
              
                flexWrap: 'wrap',
                gap: '8px',
                alignItems: 'center',
                justifyContent:'center',
                padding: '8px'
              }}
            >
              <BlackTypography variant="h4">Categories</BlackTypography>
              {my_categories && my_categories.length > 0 ? (
                isMobile ? (
                  <>
                    <Box>
                    <IconButton size="small" onClick={handleArrowClick} >
                      {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                    <Popover
                      id={id}
                      open={openpop}
                      anchorEl={anchorEl}
                      onClose={handleArrowClick}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          padding: '20px',
                          backgroundColor: colors.primary[200],
                          flexWrap: 'wrap',
                        }}
                      >
                    {my_categories.map((category) => (
                      <StyledBadge key={category.id} badgeContent={category.counts} color="secondary">
                        <Typography
                          variant="caption"
                          
                          sx={{
                            cursor: 'default',
                            padding: '4px',
                            border: `2px solid ${colors.primary[700]}`,
                            borderRadius: '50px',
                            background: `${colors.primary[400]}`,
                            flexShrink: 0,
                            transition: 'transform 0.3s ease-in-out',
                            "&:hover": {
                              transform: 'scale(1.1)',
                            },
                            width:'100%'
                          }}
                        >
                          {category.category_name}
                        </Typography>
                      </StyledBadge>
                    ))}
                  </Box>
                </Popover>
                </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '2px',
                        backgroundColor: colors.primary[200],
                        flexWrap: 'wrap',
                      }}
                    >
                      {my_categories.map((category) => (
                        <StyledBadge key={category.id} badgeContent={category.counts} color="secondary">
                          <Typography
                            variant="caption"
                            sx={{
                              cursor: 'default',
                              padding: '4px',
                              border: `2px solid ${colors.primary[700]}`,
                              borderRadius: '50px',
                              background: `${colors.primary[400]}`,
                              flexShrink: 0,
                              transition: 'transform 0.3s ease-in-out',
                              "&:hover": {
                                transform: 'scale(1.1)',
                              },
                            }}
                          >
                            {category.category_name}
                          </Typography>
                        </StyledBadge>
                      ))}
                    </Box>
                  )
                ) : (
                  <Box sx={{
                    margin:2,
                  }}>
                    <BlackTypography variant="body1" sx={{color:`${colors.redAccent[500]}`}}>No Categories</BlackTypography>
                  </Box>
                )}

            </Box>
          </Box>
          </>
        ):(<>
        <Box
          sx={{
            color:'white',
            display: 'flex',
            backgroundColor: colors.primary[700],
            alignItems: 'flex-start',
            padding: '8px',
            gap: '4px',
            width:'100%',
            height:'20%',
            minHeight:'auto',
            maxHeight:'vh',
            justifyContent:'space-between',
            overflowY:'auto',
            borderRadius:'10px'
          }}
        >
          <Box sx={{
            display: 'flex',
            flex:'1 1 50%',
            flexDirection: 'row ',
            justifyContent: 'left',
          }}>
          <Box sx={{ display: 'grid', flexDirection: 'column', alignItems: 'center', }}>
            <Avatar
              src={profile_image}
              alt={name}
              sx={{ width: 80, height: 80, margin: '4px','& .MuiAvatar-img':{
                  objectFit:'contain'
                } }}
            />
            <Box sx={{display:'flex',
                justifyContent:'center',
                width:'100%',
                borderRadius:'12px',
                border:`1px solid ${colors.primary[700]}`,
                backgroundColor:`${colors.primary[700]}`}}>
            <Typography  m="4px" variant="h5">{username}</Typography>
            </Box>
          </Box>
          <Box m="4px">
            <BlackTypography color='white' variant="h2">{name}</BlackTypography>
            <BlackTypography  color='white' variant="body1">{email}</BlackTypography>
            <BlackTypography  color='white' variant="body3">{(dob!==null)? (new Date(dob).toDateString()):''}</BlackTypography>
            </Box>
          </Box>
          {/* categories */}
          <Box
            container
            direction="column"
            sx={{
              display: 'flex',
              flex:'1 1 50%',
              flexDirection: 'column',
              overflow: 'auto',
              flexWrap: 'wrap',
              gap: '8px',
              alignItems: 'flex-end',
              padding: '8px'
            }}
          >
            <BlackTypography  color='white' variant="h4">Categories</BlackTypography>
            {my_categories && my_categories.length > 0 ? (
  isMobile ? (
    <>
      <Box>
      <IconButton size="small" onClick={handleArrowClick} >
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </IconButton>
      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleArrowClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '20px',
            backgroundColor: colors.primary[700],
            flexWrap: 'wrap',
          }}
        >
          {my_categories.map((category) => (
            <StyledBadge key={category.id} badgeContent={category.counts} color="secondary">
              <Typography
                variant="caption"
                
                sx={{
                  color: colors.grey[100],
                  cursor: 'default',
                  padding: '4px',
                  border: `2px solid ${colors.primary[700]}`,
                  borderRadius: '50px',
                  background: `${colors.primary[400]}`,
                  flexShrink: 0,
                  transition: 'transform 0.3s ease-in-out',
                  "&:hover": {
                    transform: 'scale(1.1)',
                  },
                  width:'100%'
                }}
              >
                {category.category_name}
              </Typography>
            </StyledBadge>
          ))}
        </Box>
      </Popover>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        padding: '2px',
        backgroundColor: colors.primary[700],
        flexWrap: 'wrap',
      }}
    >
      {my_categories.map((category) => (
        <StyledBadge key={category.id} badgeContent={category.counts} color="secondary">
          <Typography
            variant="caption"
            sx={{
              color: colors.grey[100],
              fontWeight:'bold',
              cursor: 'default',
              padding: '4px',
              border: `2px solid ${colors.primary[700]}`,
              borderRadius: '50px',
              background: `${colors.primary[400]}`,
              flexShrink: 0,
              transition: 'transform 0.3s ease-in-out',
              "&:hover": {
                transform: 'scale(1.1)',
              },
            }}
          >
            {category.category_name}
          </Typography>
        </StyledBadge>
      ))}
    </Box>
  )
) : (
  <BlackTypography variant="body1">No categories</BlackTypography>
)}

          </Box>
        </Box>
        </>)}
        {/* Content Area */}
        <CardContent
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            width: '100%',
          }}
        >
          {/* My number one reviews */}
          {lists&&(lists.map((list) => (
            <div key={list.id} style={{
              padding:3,
            }}>
              <Box sx={{
                padding:0.5,
                borderRadius:'4px',
                maxWidth:'max-content',
                position:'sticky',
                background:colors.grey[900],
              }}>
              <BlackTypography variant="h3" padding="2px">{list.name}</BlackTypography>
              </Box>
              <Grid container spacing={2} sx={{ padding: '10px !important',borderRadius:1 ,background:`${colors.primary[600]}`,mb:1 }}>
                {list.list_reviews && list.list_reviews.length > 0 ? (
                  list.list_reviews.map((listReview) => (
                    <Grid sx={{ padding: '10px !important' }} item xs={12} sm={6} md={4} lg={3} key={listReview.review.id}>
                      <Card
                      onClick={() => {
                        handleDetailInfo(listReview.review.id);
                      }}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: colors.primary[200],
                          transition: 'transform 0.3s ease-in-out',
                          "&:hover": {
                            transform: 'scale(1.1)',
                          }
                        }}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            width: '100%',
                            height: '200px',
                            objectFit: 'cover'
                          }}
                          image={listReview.review.media_url}
                          alt={listReview.review.id}
                        />
                      </Card>
                    </Grid>
                  ))
                ) : (
                <Box sx={{
                  margin:2,
                }}>
                  <BlackTypography variant="body1" sx={{color:`${colors.redAccent[500]}`}}>No reviews available</BlackTypography>
                </Box>
                )}
              </Grid>
            </div>
          )))}


          {/* All reviews */}
          <Box sx={{
                mt:1,
                padding:0.5,
                borderRadius:'4px',
                maxWidth:'max-content',
                position:'sticky',
                background:colors.grey[900],
              }}>
              <BlackTypography variant="h3" padding="2px">User Review</BlackTypography>
              </Box>
          <Grid container spacing={2} 
            sx={{ padding: '10px !important',
                  background: colors.primary[700],
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                  }}>
            {reviews && reviews.length > 0 ? (
              reviews.map((review) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={review.id}>
                  <Card sx={{ display: 'flex', flexDirection: 'column', backgroundColor: colors.primary[200] }}>
                    <div style={{ position: 'relative', width: '100%', height: '200px' }}>
                      <CardMedia
                        component="img"
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          transition: 'transform 0.3s ease-in-out',
                          '&:hover': {
                            transform: 'scale(1.1)',
                          },
                        }}
                        image={review.media_url || image}
                        alt={review.media_title}
                        onError={(event) => { event.target.src = image; }}
                      />
                      <BlackTypography
                        component="div"
                        variant="h3"
                        style={{
                          cursor: 'default',
                          position: 'absolute',
                          bottom: 0,
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: '12px',
                          color: colors.primary[900],
                          backgroundColor: colors.primary[700],
                        }}
                      >
                        {review.media_title}
                      </BlackTypography>
                    </div>
                    <CardContent sx={{ flex: '1 0 auto', padding: '4px' }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                        sx={{
                          mb:2,
                          paddingLeft: "10px",
                          color: colors.primary[500],
                          fontWeight: "bold",
                          fontSize: 20,
                        }}
                      >
                        Rating: {review.rating}
                      </Typography>
                      <Button
                        display="flex"
                        margin="8px"
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => {
                          handleDetailInfo(review.id);
                        }}
                      >
                        Detailed Review
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Box sx={{
                margin:4,
              }}>
                <BlackTypography variant="body1" sx={{color:`${colors.redAccent[500]}`}}>No reviews available</BlackTypography>
              </Box>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserDetail;
