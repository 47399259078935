import React, { useCallback, useState } from 'react'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
  
    CircularProgress,
    CardMedia
  } from '@mui/material';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { createApiAction } from '../../redux/apiServices';
import { useParams } from 'react-router-dom';
import { toTitleCase } from '../utils/TitleCase';
import StarRating from '../utils/CalculateRating';
import { Comment } from '../../components/Comments';
import Header from '../../components/Header';
const ReviewDetails = () => {
  const theme=useTheme();
  const colors=tokens(theme.palette.mode);
  const dispatch=useDispatch();
  const [loading, setLoading] = useState(true);
  const review =useSelector((state)=>state.api.data)
  const data= review.data.review_detail
  const {slug}=useParams();
  const ReviewDetails=useCallback(async()=>{
    await dispatch(createApiAction('/api/admin/get_review_detail','POST',{
      "review_id" :  slug
    }))
    setLoading(false);
  },[slug,dispatch])
  
  React.useEffect(()=>{
    ReviewDetails();
  },[ReviewDetails])

  if (loading) {
    return (
      <Box
        m="16px"
        sx={{
          cursor: 'default',
          display: 'flex',
          flexDirection: 'column',
          height: '90vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', margin: '0px 16px 16px 16px', height: '90%' }}>
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    sx={{
      width: '100%',
      px: 2,
      '@media (max-width: 600px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
    }}
  >
    <Box
      sx={{
        mb: 1,
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 600px)': {
          justifyContent: 'center',
          mb: 1,
        },
      }}
    >
      <Header title="Entry Details" />
    </Box>
  </Box>

  <Box m="8px" sx={{
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflow: "auto",
    backgroundColor: `${colors.primary[400]}`,
    maxHeight: '83vh',
    padding: '16px',
    borderRadius: '10px',
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 20px 40px -7px",
    '@media (max-width: 600px)': {
      maxHeight: 'none', // Allow for scrolling in mobile view
    },
  }}>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flex: '1 1 100%',
      padding: '4px',
      '@media (max-width: 600px)': {
        flexDirection: 'column',
      },
    }}>
      <CardMedia
        component="img"
        sx={{
          padding: "10px 20px 10px 5px",
          height: "fit-content",
          maxWidth: "30vh",
          maxHeight: "45vh",
          objectFit: "contain",
          '@media (max-width: 600px)': {
            maxWidth: '100%', // Full width in mobile view
            maxHeight: 'auto',
            padding: '0', // Remove padding for a cleaner layout
          },
        }}
        image={data.media_url}
        alt={data.media_title}
      />

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 100%',
        height:'auto',
        padding: '8px',
        '@media (max-width: 600px)': {
          padding: '8px 0',
        },
      }}>
        <Typography variant="h2">
          {toTitleCase(data.media_title)}
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Ratings</TableCell>
                <TableCell sx={{ fontSize: '1rem', whiteSpace: 'normal', wordWrap: 'break-word' }}><StarRating rating={data.rating} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Two-word Review</TableCell>
                <TableCell sx={{ fontSize: '1rem', whiteSpace: 'normal', wordWrap: 'break-word' }}>{data.review_short}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Full Review</TableCell>
                <TableCell sx={{ fontSize: '1rem', whiteSpace: 'normal', wordWrap: 'break-word' }}>{data.review_full}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Enjoyed</TableCell>
                <TableCell sx={{ fontSize: '1rem', whiteSpace: 'normal', wordWrap: 'break-word' }}>{data.enjoyed ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Category</TableCell>
                <TableCell sx={{ fontSize: '1rem', whiteSpace: 'normal', wordWrap: 'break-word' }}>{data.review_category.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>Note</TableCell>
                <TableCell sx={{ fontSize: '1rem', whiteSpace: 'normal', wordWrap: 'break-word' }}>{data.note}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
{/* Comments Box */}
    <Box sx={{
      margin: 2,
      backgroundColor: `${colors.primary[200]}`,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 10px -1px',
      padding: 1,
      borderRadius: '4px',
      '@media (max-width: 600px)': {
        order: 3, // Ensure it appears last
      },
    }}>
      <Box sx={{ display: 'flex', flex: '1 1 auto', alignContent: "center" }}>
        <Typography variant='h4'>{data.comment_counts}</Typography>
        <Typography variant="h4 " sx={{ paddingLeft: '4px', fontWeight: 'bold' }} gutterBottom>
          Comments
        </Typography>
      </Box>
      <Box sx={{
        marginTop: '8px',
        cursor: 'default',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflow: 'auto',
        backgroundColor: `${colors.primary[200]}`,
        borderRadius: '8px',
        width: '100%',
      }}>
        {data.review_comments.length > 0 ? data.review_comments.map((comment) => (
          <Comment key={comment.id} comment={comment} />
        )) :
          <Typography sx={{
            color: `${colors.primary[100]}`,
            fontSize: "1.2rem",
            margin: "8px",
          }}>
            No Comments
          </Typography>}
      </Box>
    </Box>
  </Box>
</Box>

  )
}

export default ReviewDetails