import { createSlice } from "@reduxjs/toolkit";

const initialState={
    success:false,
    loading:false,
    data:[],
    error:false,
    errordata:[],
    search:''
}

const apiSlice=createSlice({    
    name:"apiSlice",
    initialState,
    reducers:{
        searchFilter:(state,action)=>{
            state.search=action.payload;
        },
        clearFilter:(state)=>{
            state.search='';
        },
        fetchStart:(state)=>{
            state.loading=true;
            state.error=false;
            state.success=false;
            state.errordata=[];
        },
        fetchSuccess:(state,action)=>{
            state.loading=false;
            state.success=true;
            state.error=false;
            state.data=action.payload;
            state.errordata=[];
        },
        fetchError:(state,action)=>{
            state.error=true;
            state.loading=false;
            state.errordata=action.payload;
        }   
    },

})
export const{clearFilter,searchFilter,fetchStart,fetchSuccess,fetchError}=apiSlice.actions;
export default apiSlice.reducer;