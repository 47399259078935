import React from 'react';
import { Box, IconButton, useTheme, InputBase } from '@mui/material';
import { tokens, ColorModeContext } from '../theme';
import { useContext} from 'react';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlinedIcon from '@mui/icons-material/Person';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/userslice';
import { searchFilter } from '../redux/apislice';

const Topbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    dispatch(logout());
    navigate('/authentication');
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const handleSearchChange = (event) => {
    dispatch(searchFilter(event.target.value));
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const renderSearchIcon = () => {
    const searchPaths = ['/user', '/allReviews', '/category', '/favourites','/reportedreviews'];
    return searchPaths.includes(location.pathname);
  };

  return (
    <Box display='flex' justifyContent='space-between' padding='12px 12px 0px 12px'>
      {/* Search Box */}
      <Box>
      {renderSearchIcon() && (
        <Box 
          display="flex"
          sx={{
            background:colors.greenAccent[500],
            borderRadius: "3px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
          }}
          
        >
          <InputBase sx={{ ml: 2, flex: 1, borderRadius: 9 }} onChange={handleSearchChange} placeholder='Search' />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon /> 
          </IconButton>
        </Box>
      )}
      </Box>
      {/* Icons */}
      <Box display="flex">
      {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>  */}
        <IconButton>
          <PersonOutlinedIcon onClick={handleProfile} />
        </IconButton>
        <IconButton onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
