import React, {
  useEffect,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import {
  useSelector,
  useDispatch,
} from "react-redux";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import axios from "axios";
import { loginSuccess } from "../../redux/userslice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Header from "../../components/Header";
import { baseUrl } from "../../Base";
const Profile = () => {
  const theme = useTheme();
  const colors = tokens(
    theme.palette.mode
  );
  const [loading, setLoading] =
    useState(false);
  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state.user.currentUser
  );
  const [editMode, setEditMode] =
    useState(false);
  const [
    showChangePasswordDialog,
    setShowChangePasswordDialog,
  ] = useState(false);
  const [passwords, setPasswords] =
    useState({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
  const [userDetails, setUserDetails] =
    useState({
      id: user.data.id,
      name: user.data.name,
      profile_image:
        user.data.profile_image,
    });
  const [file, setFile] =
    useState(null);
  const accessToken =
    localStorage.getItem(
      "access_token"
    );
  const [
    showPassword,
    setShowPassword,
  ] = useState(true);
  const [
    showPassword1,
    setShowPassword1,
  ] = useState(true);
  const [
    showPassword2,
    setShowPassword2,
  ] = useState(true);
  const [errors, setErrors] = useState({
    currentPasswordError: "",
    newPasswordError: "",
    confirmNewPasswordError: "",
  });
  const [
    successDialog,
    setSuccessDialog,
  ] = useState({
    open: false,
    message: "",
  });
  const isMobile = useMediaQuery(
    theme.breakpoints.down("590")
  );

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleMouseDownPassword = (
    event
  ) => {
    event.preventDefault();
  };

  const handleChangePassword = () => {
    setShowChangePasswordDialog(true);
  };

  const handlePasswordChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [`${e.target.name}Error`]: "",
    });
  };

  const handleUserDetailsChange = (
    e
  ) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpdateDetails =
    async () => {
      const formData = new FormData();
      formData.append(
        "user_id",
        userDetails.id
      );
      formData.append(
        "name",
        userDetails.name
      );

      if (file) {
        formData.append(
          "profile_image",
          file
        );
      }

      try {
        setLoading(true);
        const response =
          await axios.post(
            `${baseUrl}/api/admin/edit_user`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type":
                  "multipart/form-data",
              },
            }
          );
        dispatch(
          loginSuccess(response.data)
        );
        setUserDetails({
          ...userDetails,
          name: response.data.data.name,
          profile_image:
            response.data.data
              .profile_image,
        });
        setEditMode(false);
        setLoading(false);
        setSuccessDialog({
          open: true,
          message:
            "Profile updated successfully!",
        });
      } catch (error) {
        console.error(
          "Error updating user details:",
          error
        );
        setLoading(false);
      }
    };

  const validatePassword = () => {
    let valid = true;
    const validateField = (
      field,
      fieldName,
      minLength = 6,
      maxLength = 20
    ) => {
      if (passwords[field] === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`${field}Error`]: `${fieldName} is required`,
        }));
        valid = false;
      } else if (
        passwords[field].length <
        minLength
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`${field}Error`]: `${fieldName} must be at least ${minLength} characters`,
        }));
        valid = false;
      } else if (
        passwords[field].length >
        maxLength
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`${field}Error`]: `${fieldName} must be less than ${maxLength} characters`,
        }));
        valid = false;
      }
    };

    validateField(
      "currentPassword",
      "Current Password"
    );
    validateField(
      "newPassword",
      "New Password"
    );

    if (
      passwords.newPassword !==
      passwords.confirmNewPassword
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmNewPasswordError:
          "Passwords do not match",
      }));
      valid = false;
    }

    return valid;
  };

  const handlePasswordUpdate =
    async () => {
      if (validatePassword()) {
        setLoading(true);
        try {
          await axios.post(
            `${baseUrl}/api/auth/change_password/`,
            {
              password:
                passwords.newPassword,
              confirm_password:
                passwords.newPassword,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setShowChangePasswordDialog(
            false
          );
          setLoading(false);
          setSuccessDialog({
            open: true,
            message:
              "Password changed successfully!",
          });
        } catch (error) {
          console.error(
            "Error changing password:",
            error
          );
          setLoading(false);
        }
      }
    };

  return (
    <Container>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: "100%",
          px: 2, // Padding for responsiveness
          "@media (max-width: 600px)": {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <Box
          sx={{
            mb: 1,
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            "@media (max-width: 600px)":
              {
                justifyContent:
                  "center",
                mb: 1, // Margin bottom for spacing in smaller screens
              },
          }}
        >
          <Header title="Your Profile" />
        </Box>
        {/* <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={() => handleClickOpen()}
            sx={{
              '@media (max-width: 600px)': {
                width: '100%',
              },
            }}
          >
            Add Review
          </Button> */}
      </Box>
      <Card
        sx={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: `${colors.primary[400]}`,
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",

        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile
              ? "column"
              : "row",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Avatar
            src={
              userDetails.profile_image
            }
            alt={user.name}
            sx={{
              width: isMobile
                ? 80
                : 180,
              height: isMobile
                ? 80
                : 180,
              border: `1px solid ${colors.primary[200]}`,
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
              justifyContent: "center",
              alignItems: isMobile
                ? "center"
                : "flex-start",
              textAlign: isMobile
                ? "center"
                : "left",
            }}
          >
            {editMode ? (
              <>
                <TextField
                  label="Name"
                  name="name"
                  value={
                    userDetails.name
                  }
                  onChange={
                    handleUserDetailsChange
                  }
                  fullWidth
                  autoComplete="off"
                />
                <Box
                  sx={{
                    backgroundColor: `${colors.primary[600]}`,
                    display: "flex",
                    alignItems:
                      "center",
                    marginTop: "10px",
                    padding: 1,
                    borderRadius: 4,
                  }}
                >
                  <input
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                    id="icon-button-file"
                    type="file"
                    onChange={
                      handleFileChange
                    }
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="secondary"
                      aria-label="upload picture"
                      component="span"
                      size="small"
                    >
                      <UploadFileIcon />
                    </IconButton>
                  </label>
                  <Typography
                    variant="body2"
                    sx={{
                      marginLeft:
                        "10px",
                    }}
                  >
                    {file
                      ? file.name
                      : "Upload Profile Image"}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    handleUpdateDetails
                  }
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
                <table
                  style={{
                    width: isMobile
                      ? "100%"
                      : "80%",
                    borderCollapse:
                      "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border:
                            "1px solid",
                          padding:
                            "8px",
                          textAlign:
                            "left",
                          fontWeight:
                            "bold",
                        }}
                      >
                        Email
                      </td>
                      <td
                        style={{
                          border:
                            "1px solid",
                          padding:
                            "8px",
                          textAlign:
                            "left",
                        }}
                      >
                        {
                          user.data
                            .email
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <table
                style={{
                  width: "100%",
                  borderCollapse:
                    "collapse",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        border:
                          "1px solid",
                        padding: "8px",
                        textAlign:
                          "left",
                        fontWeight:
                          "bold",
                      }}
                    >
                      Name
                    </td>
                    <td
                      style={{
                        border:
                          "1px solid",
                        padding: "8px",
                        textAlign:
                          "left",
                      }}
                    >
                      {user.data.name}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border:
                          "1px solid",
                        padding: "8px",
                        textAlign:
                          "left",
                        fontWeight:
                          "bold",
                      }}
                    >
                      Email
                    </td>
                    <td
                      style={{
                        border:
                          "1px solid",
                        padding: "8px",
                        textAlign:
                          "left",
                      }}
                    >
                      {user.data.email}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            display: isMobile
              ? "flex"
              : "block",
            flexDirection: isMobile
              ? "column"
              : "row",
            gap: isMobile
              ? "10px"
              : "0",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditToggle}
            sx={{
              width: isMobile
                ? "100%"
                : "auto",
            }}
          >
            {editMode
              ? "Cancel"
              : "Edit Profile"}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={
              handleChangePassword
            }
            sx={{
              marginLeft: isMobile
                ? "0"
                : "10px",
              width: isMobile
                ? "100%"
                : "auto",
            }}
          >
            Change Password
          </Button>
        </Box>
      </Card>

      <Dialog
        open={showChangePasswordDialog}
        onClose={() =>
          setShowChangePasswordDialog(
            false
          )
        }
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: `${colors.grey[500]}`,
            borderRadius: 4,
            padding: 2,
          },
          "& .MuiDialogContentText-root":
            {
              color: `${colors.primary[700]}`,
            },
          "& .MuiSvgIcon-root": {
            fill: `${colors.primary[700]}`,
          },
          "& .MuiOutlinedInput-input": {
            color: `${colors.primary[700]}`,
          },
          "& .MuiDialogTitle-root": {
            fontSize: 20,
            color: `${colors.primary[700]}`,
            fontWeight: "bold",
          },
          "& .MuiInput-root::after": {
            borderBottom: `px solid ${colors.primary[700]}`,
          },
          "& .MuiInput-root::before": {
            borderBottom: `1px solid #262429`,
          },
          "& .MuiInput-root:hover::before":
            {
              borderBottom: `1px solid ${colors.primary[500]}`,
            },
          "& .MuiInput-root:hover::after":
            {
              borderBottom: `2px solid ${colors.primary[700]}`,
            },
          "& .MuiInputLabel-root": {
            color: `${colors.primary[700]}`,
          },
          "& .Mui-focused": {
            fontWeight: "bold",
            color: `${colors.primary[700]}`,
          },
          "& .MuiInputLabel-root.Mui-focused":
            {
              color: `${colors.primary[700]}`,
            },
          "& .MuiOutlinedInput-notchedOutline":
            {
              borderColor: `${colors.primary[700]}`, // Default outline border color
            },
          "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline":
            {
              border: `2px solid ${colors.primary[700]}`, // Change outline border color on hover
            },
          "& .MuiTextField-root:hover .MuiOutlinedInput-notchedOutline":
            {
              borderBottom: `2px solid`,
              borderColor: `${colors.primary[700]}`, // Change outline border color
            },
          "& .MuiInput-root::after:hover":
            {
              borderBottom: `2px solid ${colors.primary[700]}`,
            },
        }}
      >
        <DialogTitle>
          Change Password
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your current password
            and a new password to change
            your password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Current Password"
            type={
              showPassword
                ? "password"
                : "text"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword(
                        (show) => !show
                      )
                    }
                    onMouseDown={
                      handleMouseDownPassword
                    }
                  >
                    {showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name="currentPassword"
            fullWidth
            onChange={
              handlePasswordChange
            }
            value={
              passwords.currentPassword
            }
            error={
              !!errors.currentPasswordError
            }
            helperText={
              errors.currentPasswordError
            }
          />
          <TextField
            margin="dense"
            label="New Password"
            type={
              showPassword1
                ? "password"
                : "text"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword1(
                        (show) => !show
                      )
                    }
                    onMouseDown={
                      handleMouseDownPassword
                    }
                  >
                    {showPassword1 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name="newPassword"
            fullWidth
            onChange={
              handlePasswordChange
            }
            value={
              passwords.newPassword
            }
            error={
              !!errors.newPasswordError
            }
            helperText={
              errors.newPasswordError
            }
          />
          <TextField
            margin="dense"
            label="Confirm New Password"
            type={
              showPassword2
                ? "password"
                : "text"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword2(
                        (show) => !show
                      )
                    }
                    onMouseDown={
                      handleMouseDownPassword
                    }
                  >
                    {showPassword2 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name="confirmNewPassword"
            fullWidth
            onChange={
              handlePasswordChange
            }
            value={
              passwords.confirmNewPassword
            }
            error={
              !!errors.confirmNewPasswordError
            }
            helperText={
              errors.confirmNewPasswordError
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              setShowChangePasswordDialog(
                false
              )
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={
              handlePasswordUpdate
            }
          >
            {loading ? (
              <CircularProgress
                size={24}
              />
            ) : (
              "Change Password"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: `${colors.primary[600]}`,
            borderRadius: 4,
            padding: 2,
          },
        }}
        open={successDialog.open}
        onClose={() =>
          setSuccessDialog({
            open: false,
            message: "",
          })
        }
      >
        <DialogTitle>
          Success
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {successDialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              setSuccessDialog({
                open: false,
                message: "",
              })
            }
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Profile;
