import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const SuccessDialog = ({ open, handleClose, message }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  return (
    <Dialog open={open} onClose={handleClose}
    sx={{
        '& .MuiDialog-paper': {
          backgroundColor: `${colors.grey[500]}`,
          borderRadius: 4,
          padding: 2,
        },
        '& .MuiDialogTitle-root': {
          fontSize: 20,
          color: `${colors.primary[700]}`,
          fontWeight: 'bold',
        },
        '& .MuiDialogContentText-root':{
            color: `${colors.primary[700]}`,
            fontWeight:700
        }
    }}>
      <DialogTitle>Operation Successful</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
