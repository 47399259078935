import { 
  Box,
  Button,
  useTheme,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";
import { createApiAction } from "../../redux/apiServices";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFilter } from "../../redux/apislice";
import EditIcon from '@mui/icons-material/Edit';

const Favourites = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);  
  const dispatch = useDispatch();

  const [selectedFavourite, setSelectedFavourite] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { data } = useSelector((state) => state.api);
  const searchtag = useSelector((state) => state.api.search);
  const [newFavourite, setNewFavourite] = useState({
    name: "",
    ratings: 4
  });
  const [loading, setLoading] = useState(false);
  const [validateError, setValidateError] = useState({ name: '', ratings: '' });
  const [errors, setErrors] = useState({ name: '', ratings: '' });
  const [editOpen, setEditOpen] = useState(false);
  const [editFavourite, setEditFavourite] = useState({
    id: '',
    name: '',
    ratings: 4
  });

  const getFavourites = useCallback(async () => {
    setLoading(true)
    await dispatch(createApiAction('/api/admin/fav_movies_list', 'GET'));
    setLoading(false)
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearFilter());
    getFavourites();
  }, [getFavourites, dispatch]);

  const addFavourite = async () => {
    try {
      setLoading(true);
      await dispatch(createApiAction('/api/admin/add_fav_movie', 'POST', { 
        name: newFavourite.name,
        ratings: newFavourite.ratings
      }));
      await getFavourites(); // Re-fetch favourites
      setOpen(false);
      setNewFavourite({ name: '', ratings: 4 });
    } catch (err) {
      if (err.message == 400) {
        setErrors({ name: "Favourite already exists" });
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteFavourite = async (id) => {
    try {
      setLoading(true);
      await dispatch(createApiAction('/api/admin/delete_fav_movie', 'POST', { id: id }));
      await getFavourites(); // Re-fetch favourites
      setDeleteOpen(false);
      setSelectedFavourite(null);
    } catch (err) {} finally {
      setLoading(false);
    }
  };

  const validateFavourite = () => {
    let valid = true;
    let newError = { name: "", ratings: "" };
    if (!newFavourite.name) {
      newError.name = "Favourite name is required";
      valid = false;
    }
    if (newFavourite.ratings < 4 || newFavourite.ratings > 13) {
      newError.ratings = "Ratings must be between 4 and 13";
      valid = false;
    }
    setValidateError(newError);
    return valid;
  };

  const validateFavouriteEdit = (favourite) => {
    let valid = true;
    let newError = { name: "", ratings: "" };
    if (!favourite.name) {
      newError.name = "Favourite name is required";
      valid = false;
    }
    if (favourite.ratings < 4 || favourite.ratings > 13) {
      newError.ratings = "Ratings must be between 4 and 13";
      valid = false;
    }
    setValidateError(newError);
    return valid;
  };

  const handleAddFavourite = async () => {
    if (validateFavourite()) {
      await addFavourite();
    }
  };

  const handleDelete = async () => {
    if (selectedFavourite) {
      await deleteFavourite(selectedFavourite.id);
    }
  };

  const handleEdit = (favourite) => {
    setEditFavourite({ id: favourite.id, name: favourite.name, ratings: favourite.expected_ratings });
    setEditOpen(true);
  };

  const updateFavourite = async () => {
    if (!validateFavouriteEdit(editFavourite)) return;

    try {
      setLoading(true);
      await dispatch(createApiAction('/api/admin/edit_fav_movie', 'POST', {
        id: editFavourite.id,
        name: editFavourite.name,
        ratings: editFavourite.ratings
      }));
      await getFavourites();
      setEditOpen(false);
      setEditFavourite({ id: '', name: '', ratings: 4 });
    } catch (err) {
      setErrors({ name: err.message });
    } finally {
      setLoading(false);
    }
  };

  const filteredFavourites = Array.isArray(data?.data)
    ? data.data.filter(favourite =>
        favourite?.name?.toLowerCase().includes(searchtag.toLowerCase())
      )
    : [];

  const columns = [ 
    { field: "id", headerName: "ID", minWidth: 100 },
    { field: "name", headerName: "Movie Name", flex: 1, minWidth: 100 },
    { field: "expected_ratings", headerName: "Ratings", flex: 1, minWidth: 100 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box
          display="flex"
          gap={2}
          alignItems="center"
          height="100%"
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<EditIcon />}
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<DeleteIcon />}
            onClick={() => {
              setSelectedFavourite(params.row);
              setDeleteOpen(true);
            }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Container>
      <Box sx={{
        margin: '16px',
        padding: '4px',
        justifyContent: 'center',
      }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
            px: 2, // Padding for responsiveness
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              alignItems: 'center',
            },
          }}
        >
          <Box
            sx={{
              marginLeft: '80px',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              '@media (max-width: 600px)': {
                justifyContent: 'center',
                mb: 1, // Margin bottom for spacing in smaller screens
                ml: 0
              },
            }}
          >
            <Header
              title="Renaissance Man Qualifiers"
            />
          </Box>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={() => setOpen(true)}
            sx={{
              '@media (max-width: 600px)': {
                width: '100%',
              },
            }}
          >
            Add Favourite
          </Button>
        </Box>
        <Box
          height="75vh"
          width="100%"
          sx={{
            "& .MuiDataGrid-root": {
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[900],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.primary[500]} !important`,
            },
          }}
        >{loading?(<Container sx={{display:"flex",justifyContent:"center",mt:4}}>
          <CircularProgress />
        </Container>):(
          <DataGrid rows={filteredFavourites || []} columns={columns} />

        )}
        </Box>
        {/* Dialog for adding favourite */}
        <Dialog
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor: `${colors.primary[200]}`,
              borderRadius: 4,
              padding: 2,
            },
            '& .MuiDialogTitle-root': {
              fontSize: 20,
              color: `${colors.primary[700]}`,
              fontWeight: 'bold',
            },
            '& .MuiInput-root::after': {
              borderBottom: `px solid ${colors.primary[700]}`,
            },
            '& .MuiInput-root::before': {
              borderBottom: `1px solid #262429`,
            },
            '& .MuiInput-root:hover::before': {
              borderBottom: `1px solid ${colors.primary[700]} `,
            },
            '& .MuiInput-root:hover::after': {
              borderBottom: `1px solid ${colors.primary[500]}`,
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: `${colors.primary[700]}`
            }
          }}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="add-category-dialog-title"
        >
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Favourite Name"
              type="text"
              fullWidth
              variant="outlined"
              value={newFavourite.name}
              onChange={(e) => setNewFavourite({ ...newFavourite, name: e.target.value })}
              error={!!validateError.name}
              helperText={validateError.name}
              inputProps={{
                style: { color: colors.primary[700] },
              }}
              lable={{
                style: { color: colors.primary[700] },
              }}
            />
            <TextField
              margin="dense"
              id="ratings"
              label="Ratings"
              type="number"
              fullWidth
              variant="outlined"
              value={newFavourite.ratings}
              onChange={(e) => setNewFavourite({ ...newFavourite, ratings: e.target.value })}
              error={!!validateError.ratings}
              helperText={validateError.ratings}
              inputProps={{
                style: { color: colors.primary[700] },
                min: 4,
                max: 13
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleAddFavourite}
              color="secondary"
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog for editing favourite */}
        <Dialog
          open={editOpen}
          onClose={() => setEditOpen(false)}
          aria-labelledby="edit-category-dialog-title"
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor: `${colors.primary[200]}`,
              borderRadius: 4,
              padding: 2,
            },
            '& .MuiDialogTitle-root': {
              fontSize: 20,
              color: `${colors.primary[700]}`,
              fontWeight: 'bold',
            },
            '& .MuiInput-root::after': {
              borderBottom: `px solid ${colors.primary[700]}`,
            },
            '& .MuiInput-root::before': {
              borderBottom: `1px solid #262429`,
            },
            '& .MuiInput-root:hover::before': {
              borderBottom: `1px solid ${colors.primary[700]} `,
            },
            '& .MuiInput-root:hover::after': {
              borderBottom: `1px solid ${colors.primary[700]}`,
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: `${colors.primary[700]}`
            }
          }}
        >
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="edit-name"
              label="Favourite Name"
              type="text"
              fullWidth
              variant="outlined"
              value={editFavourite.name}
              onChange={(e) => setEditFavourite({ ...editFavourite, name: e.target.value })}
              error={!!validateError.name}
              helperText={validateError.name}
              inputProps={{
                style: { color: colors.primary[700] },
              }}
            />
            <TextField
              margin="dense"
              id="edit-ratings"
              label="Ratings"
              type="number"
              fullWidth
              variant="outlined"
              value={editFavourite.ratings}
              onChange={(e) => setEditFavourite({ ...editFavourite, ratings: e.target.value })}
              error={!!validateError.ratings}
              helperText={validateError.ratings}
              inputProps={{
                style: { color: colors.primary[700] },
                min: 4,
                max: 13
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button  variant="contained" onClick={() => setEditOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={updateFavourite}
              color="secondary"
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog for deleting favourite */}
        <Dialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          aria-labelledby="delete-category-dialog-title"
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor: `${colors.primary[200]}`,
              borderRadius: 4,
              padding: 2,
            },
            '& .MuiDialogTitle-root': {
              fontSize: 20,
              color: `${colors.primary[700]}`,
              fontWeight: 'bold',
            },
            '& .MuiInput-root::after': {
              borderBottom: `px solid ${colors.primary[700]}`,
            },
            '& .MuiInput-root::before': {
              borderBottom: `1px solid #262429`,
            },
            '& .MuiInput-root:hover::before': {
              borderBottom: `1px solid ${colors.primary[500]} `,
            },
            '& .MuiInput-root:hover::after': {
              borderBottom: `1px solid ${colors.primary[500]}`,
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: `${colors.primary[500]}`
            }
          }}
        >
          <DialogContent >
            <Typography noWrap variant='h4'>
            Are you sure you want to delete{" "}
            <span style={{
              color: `${colors.redAccent[500]}`,
              fontWeight: 'bold'
            }}>"{selectedFavourite?.name}" ?</span>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button  variant="contained" onClick={() => setDeleteOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              color="secondary"
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default Favourites;
