import axios from "axios";
import {
  fetchSuccess,
  fetchError,
} from "./apislice";
import { logout } from "./userslice";
import { baseUrl } from "../Base";

// Get tokens from localStorage
const getToken = () =>
  localStorage.getItem("access_token");
const getRefreshToken = () =>
  localStorage.getItem("refresh_token");

// Create Axios instance
const apiClient = axios.create({
  baseURL: `${baseUrl}`, // Replace with your actual base URL
});

// Request interceptor to add Authorization header
apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["ngrok-skip-browser-warning"]='69420';
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle expired tokens
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired token
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();
      console.log("refreshing token")
      if (refreshToken) {
        try {
          const response = await axios.post(
            `${baseUrl}/api/auth/refresh_token`,
            {
              requestToken: refreshToken,
            }
          );

          const { access_token } = response.data.data;

          // Store the new access token
          localStorage.setItem("access_token", access_token);

          // Update the Authorization header for the retry
          apiClient.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
          originalRequest.headers["Authorization"] = `Bearer ${access_token}`;

          // Retry the original request with the new token
          return apiClient(originalRequest);
        } catch (refreshError) {
          // Handle failure to refresh the token
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("user");

          // Dispatch logout action
          // dispatch(logout());

          // Redirect to login
          window.location.href = "/authentication";
          return Promise.reject(refreshError);
        }
      } else {
        // No refresh token available
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");

        // Dispatch logout action
        // dispatch(logout());

        // Redirect to login
        window.location.href = "/";
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

// Create API action for dispatching Redux actions
export const createApiAction = (
  url,
  method,
  data = null,
  navigate
) => async (dispatch) => {
  try {
    let response;

    switch (method) {
      case "GET":
        response = await apiClient.get(url);
        break;
      case "POST":
        response = await apiClient.post(url, data);
        break;
      case "PUT":
        response = await apiClient.put(url, data);
        break;
      case "DELETE":
        response = await apiClient.delete(url);
        break;
      default:
        throw new Error(`Unsupported request method: ${method}`);
    }

    // Dispatch success action
    await dispatch(fetchSuccess(response.data));
  } catch (error) {
    const errorCode = error.response?.data?.code;

    if (errorCode === 403) {
      console.log("Invalid token");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      dispatch(logout());
      window.location.href = "/";
    }

    // Dispatch error action
    await dispatch(fetchError(error.response?.data));
    throw Error(errorCode);
  }
};
