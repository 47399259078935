import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";
import React, {
  useState,
  useEffect,
} from "react";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../Base";
const ForgotPassword = () => {
  const theme = useTheme();
  const colors = tokens(
    theme.palette.mode
  );
  const [
    errorMessage,
    setErrorMessage,
  ] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] =
    useState("");
  const [otp, setOtp] = useState("");
  const [visible, setIsVisible] =
    useState(false);
  const [emailError, setEmailError] =
    useState("");
  const [otpError, setOtpError] =
    useState("");
  const [success, setSuccess] =
    useState(false);
  const [progress, setProgress] =
    useState(0);

  const validationEmail = () => {
    let isValid = true;
    setEmailError("");
    if (!email) {
      setEmailError(
        "Email is required"
      );
      isValid = false;
    } else if (
      !/\S+@\S+\.\S+/.test(email)
    ) {
      setEmailError("Email is invalid");
      isValid = false;
    }
    return isValid;
  };

  const validationOtp = () => {
    let isValid = true;
    setOtpError("");
    if (!otp) {
      setOtpError("OTP is required");
      isValid = false;
    } else if (!/^\d{6}$/.test(otp)) {
      setOtpError(
        "OTP must be a 6-digit number"
      );
      isValid = false;
    }
    return isValid;
  };

  const handleForgotPassword = async (
    e
  ) => {
    e.preventDefault();
    if (!validationEmail()) return;
    try {
      const response = await fetch(
        `${baseUrl}/api/auth/forget_password`,
        {
          method: "POST",
          body: JSON.stringify({
            email,
          }),
          headers: {
            "Content-Type":
              "application/json",
          },
        }
      );
      const data =
        await response.json();
      if (response.status === 200) {
        setIsVisible(true);
      } else {
        setErrorMessage(
          data.message ||
            "An error occurred"
        );
      }
    } catch (err) {
      setErrorMessage(
        "An error occurred. Please try again."
      );
    }
  };

  const handleChangePassword = async (
    e
  ) => {
    e.preventDefault();
    if (
      !validationEmail() ||
      !validationOtp()
    )
      return;
    try {
      const response = await fetch(
        `${baseUrl}/api/auth/verifyOTP`,
        {
          method: "POST",
          body: JSON.stringify({
            email,
            otp,
          }),
          headers: {
            "Content-Type":
              "application/json",
          },
        }
      );
      const data =
        await response.json();
      if (response.status === 200) {
        setSuccess(true);
        let timer = 5;
        const interval = setInterval(
          () => {
            setProgress(
              (prev) => prev + 20
            );
            timer--;
            if (timer <= 0) {
              clearInterval(interval);
              navigate("/");
            }
          },
          1000
        );
      } else {
        setErrorMessage(
          data.message ||
            "An error occurred"
        );
      }
    } catch (err) {
      setErrorMessage(
        "An error occurred. Please try again."
      );
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color:'white',
        background: `linear-gradient(174.66deg, #282842 4.27%, #3B2146 100.62%)`,
      }}
    >
      <Container
        maxWidth="sm"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
            height: "100%",
            padding: "20px",
            borderRadius: "20px",
            position: "relative", // Added for corner elements
          }}
        >
          <Box>
            <Box
              sx={{
                position: "relative",
                top: 0,
                right: "4%",
                width: "10px",
                height: "10px",
                backgroundColor:
                  "white",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontFamily:
                  "Sharnay Heavy",
                fontSize: "45.44px",
                fontWeight: 700, // Adjusted to make it bold
                lineHeight: "36.66px",
                letterSpacing: "0.05em",
                textAlign: "left",
                border:
                  "3px solid white", // Added border
                padding: "10px 20px", // Added padding inside border
              }}
            >
              ZIGGLU
            </Typography>
            <Box
              sx={{
                position: "relative",
                bottom: 0,
                left: "100%",
                width: "10px",
                height: "10px",
                backgroundColor:
                  "white",
              }}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              fontFamily: "Roboto",
              fontSize: "30px",
              fontWeight: 500,
              lineHeight: "35.16px",
              letterSpacing: "-0.01em",
              textAlign: "left",
            }}
          >
            It’s Social. Media.
          </Typography>
        </Box>
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            borderRadius: "16px",
            width: "100%",
            backgroundColor: `${colors.primary[200]}`,
            justifyContent: "center",
          }}
        >
          {success ? (
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
              >
                Success!
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
              >
                Password reset link has
                been sent to your email.
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ mt: 2 }}
              />
              <Typography
                variant="body2"
                sx={{ mt: 1 }}
              >
                Redirecting in{" "}
                {5 - progress / 20}{" "}
                seconds...
              </Typography>
            </Box>
          ) : (
            <>
              <Typography
                variant="h3"
                sx={{
                  cursor: "default",
                }}
                gutterBottom
              >
                Forgot Password
              </Typography>
              <form
                onSubmit={
                  visible
                    ? handleChangePassword
                    : handleForgotPassword
                }
              >
                <TextField
                  autoComplete="off"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) =>
                    setEmail(
                      e.target.value
                    )
                  }
                  error={!!emailError}
                  helperText={
                    emailError
                  }
                  onClick={() =>
                    setErrorMessage("")
                  }
                />
                {!visible && (
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor:
                        colors
                          .primary[500],
                      display: "flex",
                      margin: "auto",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    Send Reset Link
                  </Button>
                )}
                {visible && (
                  <>
                    <TextField
                      autoComplete="off"
                      label="OTP"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={otp}
                      onClick={() =>
                        setErrorMessage(
                          ""
                        )
                      }
                      onChange={(e) =>
                        setOtp(
                          e.target.value
                        )
                      }
                      error={!!otpError}
                      helperText={
                        otpError
                      }
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor:
                          colors
                            .primary[500],
                        display: "flex",
                        margin: "auto",
                        width: "100%",
                        marginTop:
                          "20px",
                      }}
                    >
                      Verify OTP
                    </Button>
                  </>
                )}
              </form>
              {errorMessage && (
                <div className="popup">
                  <Paper
                    elevation={5}
                    style={{
                      color: `${colors.redAccent[500]}`,
                      backgroundColor: `${colors.primary[400]}`,
                      padding: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <Typography variant="body1">
                      !{errorMessage}
                    </Typography>
                  </Paper>
                </div>
              )}
            </>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
